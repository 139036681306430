import {animate} from "motion";

(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);
  const letters = document.querySelectorAll('.secret__opera');
  const randomLetters = [];

  function handleDOMContentLoaded() {
    letters.forEach((element) => {
      randomLetters.push(element);
    });

    shuffle(randomLetters);
    randomLetters.forEach((element, index) => {
      animate(element, { "--opacity": 1 }, { duration: 3, repeat: Infinity, repeatType: "reverse", repeatDelay: 5, delay: 5 * index })
    });
  }

  function shuffle(array) {
    let currentIndex = array.length;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {

      // Pick a remaining element...
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  }
})();
